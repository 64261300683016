.layout-wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &.content {
    main {
      padding: 4rem;
    }
  }
  &.panes {
    main {
      overflow: hidden;
    }
  }
  &.loading,
  &.loading-questions {
    main {
      justify-content: center;
      align-items: center;
    }
  }
  main {
    flex-grow: 1;
    display: flex;
    height: 100%;

    .panes {
      display: flex;
      flex-grow: 1;
      overflow: hidden;

      .pane {
        flex-basis: 50%;
        flex-grow: 1;
        height: 100%;
        padding: 4rem;

        &.scroll {
          overflow-y: scroll;
        }
        h3.pane-section-heading {
          font-size: 2rem;
          border-bottom: 0.1rem solid #999;
          padding: 2rem 0;
          margin-bottom: 4rem;
        }
      }
      .pane-alt {
        padding: 4rem;
        background-color: $lightGrey;
        height: 100%;
      }
      .pane-narrow {
        flex-basis: 20%;
      }
    }
    .layout-full {
      padding: 4rem;
      width: 100%;
      display: flex;
      flex-direction: column;

      &.no-padding {
        padding: 0rem;
      }
    }
  }
  .content {
    padding: 4rem;
    width: 100%;
  }
}
.title-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
  }
}
