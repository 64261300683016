.layout-wrap.field {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #f5f6f0;
  position: fixed;
  top: 0;
  left: 0;

  @media (min-width: 540px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 54rem;
    max-height: 100rem;
    border: solid 0.1rem #000;
  }

  .debug-button {
    padding: 0.3rem;
    font-size: 1.2rem;
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
  }
  .field-panel {
    width: 100%;
    height: 100%;

    &.field-main {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .field-content {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .fields-index-wrap {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;

          .fields-index {
            flex-shrink: 1;
            overflow-y: scroll;

            // .fields-index-scroll {
            .fields-index-field {
              background: #fff;
              height: 7rem;
              display: flex;
              border-bottom: 0.1rem solid #000;
              color: #000;

              .icon {
                width: 7rem;
                height: 7rem;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;

                svg.lock {
                  width: 100%;
                  height: 100%;
                }
              }
              .info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 1;
                border-left: 0.1rem solid #000;

                h3,
                h4 {
                  flex: 1;
                  padding: 0 2rem;
                  font-size: 1.2rem;
                  margin: 0;
                  font-weight: normal;
                  text-transform: uppercase;
                  display: flex;
                  align-items: center;
                  color: #000;
                }
                h3 {
                  font-weight: bold;
                  border-bottom: 0.1rem solid #000;
                }
              }
              &:not(.active) {
                background: #f6f7f1;
              }
            }
            // }
          }
          footer.fields-index-footer {
            flex-shrink: 0;
            height: 18rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #fff;
            border-top: 0.1rem solid #000;
            position: relative;
            overflow: hidden;

            .top-bar {
              flex-grow: 1;
              padding: 2rem 12rem 2rem 2rem;

              > svg {
                position: absolute;
                bottom: 1rem;
                right: -4rem;
                z-index: 2;
              }
            }
            .bottom-bar {
              background: #000;
              height: 5rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-shrink: 0;
              padding: 0 1.6rem;
              position: relative;
              z-index: 3;

              svg,
              img {
                height: 3rem;
                width: auto;
              }
            }
          }
        }
        .field-panel.onboarding {
          position: absolute;
          top: 0;
          left: 0;
          background: #f5f6f0;
          border: none;
        }
      }
    }
  }
}
