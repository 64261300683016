@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
svg.spinner {
  transform-origin: center;
  animation: spin 15s linear infinite;
}
.content-loader {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;

  svg {
    margin-bottom: 2rem;
  }
  button {
    margin-bottom: 2rem;
  }
}
