.fields {
  .title-bar {
    align-items: center;

    a {
      color: #999;
      font-size: 1.6rem;
      margin-left: auto;
      margin-right: 4rem;
    }
  }
  .edit-items {
    margin-top: 4rem;
  }
}
.inactive-fields {
  .title-bar {
    h1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        margin-right: 2rem;
        display: block;
      }
    }
  }
  .edit-row {
    button {
      padding: 0.5rem 1rem;
      background: #ddd;
      color: #333;
      font-size: 1.4rem;
      border-radius: 0.3rem;

      &:hover {
        background: #aaa;
      }
    }
  }
}
