header {
  height: 8rem;
  background: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  color: #ffffff;

  nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;

    a {
      color: inherit;
      margin-right: 4rem;
      // font-weight: $bold;

      &[aria-current="page"] {
        text-decoration: underline;
      }
      &:last-of-type {
        margin-right: auto;
      }
    }
    button {
      margin-right: 4rem;
      padding: 0;
      background: none;

      &:hover {
        background: none;
        text-decoration: underline;
      }
    }
  }
  a.site-title {
    font-weight: $light;
    letter-spacing: 0.5rem;
    margin-left: auto;
    font-size: 3.6rem;
    color: #fff;
    text-decoration: none;

    svg {
      display: block;
      height: 3rem;
      width: auto;
    }
  }
}
.sub-head {
  padding: 2rem 4rem;
  background: lightgray;
}
