.field-overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;

  .field-overlay-content-inner {
    pointer-events: all;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 2rem;
    overflow-y: scroll;
  }
}
