.field-conversation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 23.5rem;

  .blocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .swiper-container {
    height: 100%;

    .swiper-slide {
      width: calc(100% - 6rem);

      .convo-tile {
        background: #000;
        border: 0.1rem solid #000;
        height: calc(100% - 1rem);
        margin: 0 1rem;
        display: flex;
        flex-direction: column;
        position: relative;

        &:after {
          content: "";
          height: 0.1rem;
          width: 3rem;
          display: block;
          top: calc(50% - 0.5rem);
          left: 100%;
          position: absolute;
          background: #000;
        }

        .card-content {
          padding: 0 4rem;
          text-align: center;
          color: #fff;
          flex-grow: 1;
          font-size: 1.4rem;
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {
            display: block;
            margin-bottom: 1rem;
          }
          button {
            font-size: 1.4rem;
            text-decoration: underline;
            padding: 0.5rem;

            &:active,
            &:hover,
            &:focus {
              background: #000;
            }
          }
        }
        .meta {
          height: 4.4rem;
          font-size: 1rem;
          text-transform: uppercase;
          color: #fff;
          display: flex;
          flex-direction: column;

          span {
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            border-top: 0.1rem solid #000;

            &:first-child {
              background: #1a1a19;
            }
            &:last-child {
              background: #2e2e2d;
            }
          }
        }
        &.answer {
          color: #000;
          background: #fff;
          border: 0.1rem solid #000;

          .card-content {
            color: #000;
          }
          .meta {
            color: #000;

            span {
              background-color: #fff;
            }
          }
        }
      }
      &:last-child {
        .convo-tile:after {
          display: none;
        }
      }
    }
  }
}
