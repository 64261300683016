.moderation {
  .edit-items {
    a {
      text-decoration: none;
    }
    .top-row {
      display: flex;
      margin: 1rem 0;
      color: #000;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.1rem solid #000;
      padding: 1.5rem 3rem;
      margin-bottom: 2rem;

      div.measure {
        padding: 0;
        font-size: 1.4rem;
        font-weight: 200;

        &:before {
          display: none;
        }
      }
      svg,
      span.title {
        visibility: hidden;
      }
    }
    span.title {
      flex-grow: 1;
    }
    div.measure {
      width: 10%;
      min-width: 12rem;
      padding: 0;
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      &:before {
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        background: #c4c4c4;
        content: "";
        margin-right: 1rem;
      }
      &.moderated:before {
        background: #9cee32;
      }
      &.rejected:before {
        background: #ee6a32;
      }
    }
  }
}
.field-moderation {
  .title-bar {
    align-items: center;
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid #000;
    margin-bottom: 4rem;

    .title {
      a {
        padding-right: 3rem;
      }
    }
    .filter {
      button {
        height: 4rem;
        border-radius: 2rem;
        padding: 0 3rem;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        font-size: 1.6rem;
        background: #f8f8f8;
        color: #000;
        margin: 1rem;
        text-transform: capitalize;

        &.active {
          background: #000;
          color: #fff;
        }
      }
    }

    @media (max-width: 1300px) {
      display: block;

      .filter {
        margin-top: 2rem;
      }
    }
  }
  .question-row {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;

    .question-row-content {
      border-radius: 3rem;
      border: 0.1rem solid #000;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .a,
      .q {
        flex: 1;
        padding: 2rem;
        font-size: 1.6rem;
        align-items: center;
      }
      .a {
        border-bottom: 0.1rem solid #000;
        display: flex;
        justify-content: space-between;

        .answer-content {
          margin-right: auto;
          padding-right: 2rem;
        }
        span.gen {
          background: #f1f1f1;
          padding: 1rem 2rem;
          margin-left: 0.5rem;
          flex-shrink: 0;
        }
      }
      .q {
        background: #f8f8f8;
        font-weight: 600;
      }
    }
    .question-row-controls {
      display: flex;

      button {
        width: 12rem;
        height: 100%;
        margin-left: 2rem;
        border-radius: 1rem;
        background: #f1f1f1;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;

        &:disabled {
          opacity: 0.3;
          pointer-events: none;
        }
        &:hover,
        &:focus {
          background: #ddd;
        }
        &.active {
          background: #000;

          svg * {
            fill: #fff;
          }
        }
      }
    }
  }
}
