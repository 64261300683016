.questions {
  overflow-y: scroll;
  overflow: auto;
  height: auto;
}
.questions-inner {
  max-width: 80rem;
  margin: 0 auto;
  display: block;

  .question {
    padding: 2rem 0 4rem;
    border-bottom: 0.1rem solid $grey;

    button {
      margin-right: 1rem;

      &.accept.active {
        background: green;
      }
      &.reject.active {
        background: red;
      }
    }
  }
}
