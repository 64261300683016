.label-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 5rem 3rem;
  justify-content: space-between;
  overflow: hidden;
  user-select: none;

  * {
    user-select: none;
  }

  .label {
    height: 100%;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    z-index: 100;

    .label-inner {
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 10rem;
      padding-top: 4rem;
      padding-right: 12rem;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      height: 8rem;
      width: 100%;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(#fff, 0),
        rgba(#fff, 1) 95%
      );
    }

    h1 {
      text-transform: uppercase;
      margin: 0;
      display: flex;
      align-items: center;
      line-height: 1.33;
      font-size: 2.4rem;
      text-decoration: underline;
      font-weight: $bold;
      font-size: 3.4rem;
    }
    h2 {
      margin: 0;
      font-weight: normal;
      font-size: 2.6rem;
    }
    .description,
    .about {
      margin-top: 2rem;
      font-size: 2.5rem;
    }
    // .about {
    //   &:before {
    //     content: "";
    //     display: block;
    //     height: 0.1rem;
    //     width: 10rem;
    //     background: #000;
    //     margin-bottom: 2rem;
    //   }
    // }
  }
  .field {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    overflow: visible;
    pointer-events: none;

    .question {
      border: 0.1rem solid #000;
      margin-right: 3rem;
      flex-grow: 1;
      position: relative;
      z-index: 11;
      background: #fff;
      min-height: 100%;
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
        background: #000;
        color: #fff;
        padding: 2rem 12rem 2rem 2rem;
        font-size: 1.9rem;
        position: relative;
        flex-grow: 1;

        &:after {
          content: "";
          width: 2rem;
          height: 2rem;
          transform-origin: center;
          transform: rotate(45deg);
          background: #000;
          position: absolute;
          bottom: 1.5rem;
          right: -1rem;
        }
      }
      div {
        padding: 1rem 1.5rem;
        font-size: 1.5rem;
        text-transform: uppercase;
        line-height: 1;
        flex-grow: 0;
      }
    }
    .code {
      width: 20%;
      flex-shrink: 0;
      position: relative;
      overflow: visible;
      background: #fff;

      canvas {
        display: block;
        width: 100% !important;
        height: auto !important;
        z-index: 10;
        position: relative;
      }
      .field-anim {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
      }
    }
  }
  &.large {
    .label {
      h1 {
        font-size: 5rem;
      }
      h2 {
        font-size: 4rem;
      }
    }
    .description,
    .about {
      font-size: 4rem;
    }
  }
}
.buttons {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 0.1rem solid #000;
  z-index: 101;

  button {
    width: 11rem;
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid #000;
    border-bottom: none;
    background: #fff;
    color: #000;
    font-weight: $bold;
    font-size: 2.2rem;
    padding: 1rem;

    &.active {
      background: #000;
      color: #fff;
    }
  }
}
