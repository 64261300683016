
.layout-wrap.vis {
  background-color: #f5f6f0;
  overflow: hidden;
  filter: invert(1);

  main {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
    position: relative;

    .field-svg-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 180%;
      height: auto;
      object-fit: contain;

      path {
        stroke-width: 0.5;
      }
    }
  }
  &.transition-out {
    main {
      opacity: 0;
    }
  }
  .field-conversation {
    height: 38rem;

    .convo-tile {
      .card-content {
        font-size: 2.8rem;

        button {
          display: none;
        }
      }
      .meta {
        height: 10rem;
        font-size: 1.6rem;

        span {
          padding: 0 2rem;
        }
      }
      div.Typist {
        display: inline;

        .Cursor {
          display: inline;
        }
      }
    }
  }
  @media (min-width: 1280px) {
    main {
      .field-svg-wrap {
        width: 50%;
      }
    }
    .field-conversation {
      height: 30rem;

      .convo-tile {
        .card-content {
          font-size: 1.8rem;
        }
        .meta {
          font-size: 1.2rem;
        }
      }
      .swiper-container {
        .swiper-slide {
          max-width: 60rem;
        }
      }
    }
  }
}
.field-vis {
  flex-grow: 1;

  .force-graph-container {
    width: 100%;
    height: 100%;
  }
}
.vis-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .vis-header {
    color: #000;
    padding: 0;
    height: 8rem;
    background-color: transparent;
    border-color: transparent;
    position: absolute;
    width: 100%;
    transition: background-color 1s ease, border-color 1s ease;

    .logo {
      padding: 0 2rem;

      svg {
        height: 3.4rem;
        width: auto;
        display: block;

        * {
          fill: rgba(#000, 0.1);
          transition: fill 0.8s ease;
        }
      }
    }
    .title {
      border-left: 0.1rem solid #000;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding: 1rem 2rem;
      opacity: 0;
      transition: opacity 1s ease;

      h1,
      h2 {
        margin: 0;
        padding: 0;
        border: 0;
        height: auto;
        font-size: 1.6rem;
        line-height: 1;
        flex-grow: 0;
      }
      h1 {
        font-size: 2.6rem;
        margin-bottom: 0.8rem;
        font-weight: normal;
        text-transform: none;
      }
      h2 {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  .field-conversation {
    transform: translateY(100%);
    transition: transform 1s ease;
  }
  &.showing {
    .vis-header {
      background-color: #fff;
      border-color: #000;

      .logo {
        svg {
          * {
            fill: #000;
          }
        }
      }
      .title {
        opacity: 1;
      }
    }
    .field-conversation {
      transform: translateY(0);
    }
  }
  @media (min-width: 1280px) {
    .vis-header {
      .title {
        h1,
        h2 {
          font-size: 1.6rem;
        }
        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }
}
