.terminal {
  display: flex;
  flex-direction: column;

  &.layout-full {
    padding: 0 !important;
  }
  .messages {
    flex-grow: 1;
    position: relative;

    .messages-inner {
      position: absolute;
      bottom: 0;
      left: 0;
      overflow: auto;
      height: 100%;
      width: 100%;
    }
    .messages-scroll {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 100%;
      padding: 3rem;

      .chat-message {
        border-radius: 0.5rem;
        background: $grey;
        padding: 2rem;
        margin-bottom: 3rem;
        width: 70%;
        position: relative;

        .chat-arrow {
          position: absolute;
          bottom: -0.35rem;
        }

        &.me {
          margin-left: auto;

          .chat-arrow {
            right: -0.35rem;
          }
        }

        &.them {
          background: #000;
          color: #fff;

          .chat-arrow {
            transform: rotate(90deg);
            right: auto;
            left: -0.35rem;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  form {
    height: 10rem;
    padding: 0;
    border-top: 0.1rem solid $grey;
    display: flex;

    textarea {
      border: 0;
      outline: 0;
      resize: none;
      width: 100%;
      display: block;
      height: 100%;
      padding: 2rem;
      // font-family: "Gothic A1", sans-serif;
      font-family: $baseFont;
      font-weight: $bold;
      font-size: 1.6rem;
    }
    button {
      background: none;
      padding: 0 2rem;
      border: 0;
      outline: 0;
    }
  }
}
