.manage-fields {
  .field-form {
    h1 {
      margin-bottom: 0;
    }
    .field-url {
      font-style: italic;
      color: $grey;
      margin-top: 0;
    }
  }
  canvas {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  textarea {
    min-height: 40rem;

    &.primer-test-field {
      min-height: 10rem;
    }
  }
  .form-row.select-persona {
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid $grey;
  }
  .form-row.submit {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 0.1rem solid $grey;
  }
  .form-row.result {
    background: #000;
    padding: 3rem;
    color: #fff;
    border-radius: 0.8rem;
  }
  .form-row.image {
    img {
      width: 15rem;
      margin: 2rem 0;
    }
  }
  .copy-area {
    position: fixed;
    top: -10000rem;
    left: -10000rem;
  }
  .panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 1rem;
      font-size: 1.6rem;
    }
  }
  button.copy-button {
    color: #666;
    background: none;
    float: right;
    font-size: 1.4rem;

    &:hover {
      color: #000;
    }
  }
  .button-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .refresh-button {
      background: none;
      padding: 0;
      color: #818181;
      font-size: 1.6rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1rem;
      }
      &:hover {
        color: #000;

        svg * {
          fill: #000;
        }
      }
    }
    label {
      margin: 0;
    }
  }
  .file-input-label {
    input {
      display: none;
    }
    span {
      @extend .button;
      font-weight: $regular;
      display: inline-block;
    }
  }
  .primer-test-question {
    margin: 0;
    padding: 2rem;
    background: #fff;
  }
  .qa {
    margin-bottom: 2rem;
    padding-right: 5rem;
    position: relative;

    .qa-input {
      background: rgba($lightGrey, 0.5);
      display: flex;
      align-items: center;
      padding-left: 2rem;
      font-size: 1.4rem;
      border-radius: 0.5rem 0.5rem 0 0;

      &:nth-child(2) {
        background: #878787;
        border-radius: 0 0 0.5rem 0.5rem;

        input,
        span {
          color: #fff;
        }
      }
      span {
        color: #000;
        font-weight: $bold;
        margin-right: 1rem;
      }
      input {
        color: #000;
        border: none;
        outline: 0;
        background: transparent;
        font-size: 1.4rem;
      }
    }
    .removeButton {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      padding: 0;
      background: rgba(black, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $bold;
      line-height: 1;
      text-indent: -10000rem;

      &:after {
        content: "";
        width: 1.5rem;
        height: 0.3rem;
        background-color: #fff;
        display: block;
      }

      &:hover {
        background: rgba(red, 0.5);
      }
    }
  }
}
