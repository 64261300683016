html {
  box-sizing: border-box;
  font-size: 62.5%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  height: 100%;
  overscroll-behavior: none;
  padding: 0;
  margin: 0;
}
html.label {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  body {
    position: fixed;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
}
body {
  font-size: 1.8rem;
  line-height: 1.5;
  // font-family: "Gothic A1", sans-serif;
  font-family: $baseFont;
}
a {
  text-decoration: none;

  &:hover,
  a.active {
    text-decoration: underline;
  }
}

/**
* Forms 
**/
.form-row {
  margin-bottom: 3rem;

  label {
    display: block;
    font-size: 1.6rem;
    font-weight: $bold;
    margin-bottom: 1rem;
  }

  &.two-up {
    display: flex;
    margin-right: -3rem;

    > div {
      margin-right: 3rem;
      flex-grow: 1;
    }
  }
}
textarea {
  border: 0.1rem solid #c1c1c1;
  outline: 0;
  resize: none;
  width: 100%;
  display: block;
  height: 100%;
  padding: 2rem;
  // font-family: "Gothic A1", sans-serif;
  font-family: $baseFont;
  font-weight: $regular;
  font-size: 1.6rem;
}
input[type="number"],
input[type="text"] {
  width: 100%;
  padding: 0 2rem;
  // font-family: "Gothic A1", sans-serif;
  font-family: $baseFont;
  font-weight: $regular;
  font-size: 1.8rem;
  line-height: 1;
}
input[type="submit"],
button,
a.button,
.button {
  color: #fff;
  background: #000;
  outline: 0;
  border: 0;
  padding: 1.5rem 3rem;
  font-family: $baseFont;
  cursor: pointer;
  transition: background-color 0.5s ease;
  font-size: 1.8rem;

  &:hover {
    background-color: lighten(#000, 50%);
  }
}
select {
  border: none;
  outline: none;
  padding: 0 6rem 0 2rem;
  display: block;
  width: 100%;
  font-size: 1.8rem;
  font-weight: $regular;
  -webkit-appearance: none;
  background: #fff url(../../common/icons/down-arrow.svg) right 2rem center
    no-repeat;
}
textarea,
input[type="number"],
input[type="text"],
input[type="email"],
select {
  border: 0.1rem solid #000;
}
input[type="number"],
input[type="text"],
input[type="email"],
select {
  height: 6rem;
  display: flex;
  align-items: center;
}
button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
label.checkbox {
  cursor: pointer;

  input {
    display: none;
  }
  span {
    display: block;
    padding-left: 6rem;
    padding-top: 1.3rem;
    position: relative;
  }
  span:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 5rem;
    height: 5rem;
    border: 0.1rem solid #000;
    display: block;
  }
  input:checked ~ span {
    &:before {
      background: url(../../common/icons/check.svg) center no-repeat;
      background-size: contain;
    }
  }
}

.code-holder {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  padding: 3rem;

  &.contact {
    img {
      width: 15rem;
      height: 15rem;
      object-fit: cover;
      border-radius: 7.5rem;
      overflow: hidden;
    }
  }
}
.edit-items {
  margin-top: 4rem;
  padding-bottom: 8rem;

  a,
  .edit-row {
    display: flex;
    margin: 1rem 0;
    background: #f8f8f8;
    color: #000;
    border-radius: 0.6rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 3rem;
  }
  a:hover {
    background: #eee;
  }
}
header.subnav {
  background: $grey;

  button:not(.big-button),
  a {
    margin: 0 3rem 0 0;
    background: none;
    padding: 0;
    color: #000;

    &:hover,
    &.active {
      text-decoration: underline;
    }
  }
}
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}
.button-list-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem;
  width: 100%;

  .button-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    a {
      margin: 0.5rem;
    }
  }
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: #000;
}

.ql-container.ql-snow {
  // font-family: "Gothic A1", sans-serif;
  font-family: $baseFont;
  font-size: 1.8rem;
  padding: 1rem;

  p {
    margin-bottom: 1em;
  }
}
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  form {
    background-color: #fff;
    border: 0.2rem solid #000;
    padding: 8rem;
    width: 100%;
    margin: 4rem;
    max-width: 80rem;
    pointer-events: all;

    h3 {
      text-align: center;
      margin: 0;
    }
    button.cancel {
      margin-left: 2rem;
      color: #000;
      border: 0.1rem solid #000;
      background: #fff;
    }
  }
  &.overlaySaving form {
    pointer-events: none;
    opacity: 0.5;
  }
}

.field-svg-wrap {
  path.field-svg {
    animation: opacityFade 3.5s ease-in infinite;
    stroke-opacity: 0;

    &.field-svg-01 {
      animation-delay: 0;
    }
    &.field-svg-02 {
      animation-delay: 0.3s;
    }
    &.field-svg-03 {
      animation-delay: 0.6s;
    }
    &.field-svg-04 {
      animation-delay: 0.9s;
    }
  }
}
@keyframes opacityFade {
  0% {
    stroke-opacity: 0;
  }
  50% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
