.field {
  .field-panel.onboarding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 0.1rem solid #000;

    .onboarding-slide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      overflow: hidden;

      .onboarding-image {
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: auto;
          width: 60%;
        }
      }
      .onboarding-copy {
        background: #fff;
        border-top: 0.1rem solid #000;
        height: 25rem;
        padding: 4rem;

        p {
          margin-top: 0;
        }
      }
      &.onboarding-slide-01 {
        .onboarding-image {
          padding: 2rem;
        }
      }
      &.onboarding-slide-04 {
        .onboarding-image {
          img {
            height: 100%;
            width: auto;
            object-fit: contain;
          }
        }
      }
      &.onboarding-slide-03 {
        .onboarding-image {
          img {
            width: calc(100% + 0.2rem);
            height: auto;
          }
        }
      }
      &.onboarding-slide-02 {
        .onboarding-image {
          padding: 2rem;

          img {
            width: 80%;
            height: auto;
          }
        }
      }
    }
    .onboarding-nav {
      height: 5rem;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-top: 0.1rem solid #000;
      font-size: 1.4rem;
      text-transform: uppercase;
      font-weight: lighter;

      button {
        width: 10rem;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: lighter;

        &:active,
        &:focus {
          background: #000;
        }
        &.onboarding-nav-prev {
          border-right: 0.1rem solid #000;
        }
        &.onboarding-nav-next {
          border-left: 0.1rem solid #000;
        }
      }
      .onboarding-nav-progress {
        flex-grow: 1;
        justify-content: center;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}
