nav.field-nav,
.vis-wrapper .vis-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  border-top: 0.1rem solid #000;
  border-bottom: 0.1rem solid #000;
  background: #fff;
  flex-shrink: 0;

  @media (min-width: 1023px) {
    border-top: none;
  }
  button {
    background: transparent;
    border: 0;
    color: #000;
    font-size: 1.2rem;
    display: flex;
    text-transform: uppercase;
    width: 9.5rem;
  }
  h1 {
    border-left: 0.1rem solid #000;
    height: 100%;
    font-size: 1.2rem;
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 2rem;
    margin: 0;
  }
  .index-close-button {
    line-height: 1;
    svg {
      margin-right: 1rem;
    }
  }
}
