.field-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 11rem;

  .answer-ui {
    padding: 0 2rem;
    height: 6.5rem;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    button.answer-button {
      font-weight: 200;
      height: 4.4rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      padding: 0 2rem;
      text-align: center;
      flex-grow: 1;
      margin-right: 1rem;
      line-height: 1;
    }
    button.info-button {
      border: 0.1rem solid #000;
      background: #fff;
      font-weight: 200;
      height: 4.4rem;
      width: 4.4rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      padding: 0;
      text-align: center;
    }
  }
  .field-data {
    display: flex;
    height: 4.5rem;
    border-top: 0.1rem solid #000;

    .field-data-cell {
      border-right: 0.1rem solid #000;
      background: #fff;
      font-size: 1rem;
      font-weight: 200;
      height: 100%;
      flex: 1;
      padding: 1rem;
      position: relative;

      &:last-child {
        border-right: none;
      }
      h5 {
        margin: 0 0 0.5rem;
        font-weight: 200;
        text-transform: uppercase;
        line-height: 1;
      }
      svg {
        position: absolute;
        bottom: 0.8rem;
        right: 1rem;
      }
    }
  }
}
