.field {
  .field-panel.field-intro {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;

    .load-text {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 1.4rem;
      padding: 0.3rem 1rem;
    }
    .intro-content {
      flex-grow: 1;
      position: relative;

      .field-intro-bg-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 1s ease;

        &.loaded {
          opacity: 1;
        }
      }
      svg.dotted-rect {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      svg.spinner,
      svg.crescent {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
      .load-text {
        background: #000;
        color: #fff;
      }
    }
    footer {
      height: 5rem;
      flex-shrink: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      padding: 0 2rem;
      background: #f6f7f2;

      img,
      svg {
        height: 2.5rem;
        width: auto;
      }
    }
  }
}
