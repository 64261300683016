.field-question {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f5f6f0;

  nav {
    display: flex;
    align-items: center;
    padding: 3rem 2rem;

    &:before {
      content: "";
      height: 0.1rem;
      background: #000;
      flex-grow: 1;
      margin-right: 4rem;
    }
    button {
      background: transparent;
      border: 0;
      color: #000;
      font-size: 1.2rem;
      display: flex;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 0;

      svg {
        margin-right: 1rem;
      }
    }
  }
  .panel-content {
    .panel-question {
      font-size: 2rem;
      margin: 3rem 0;
      padding-right: 8rem;
    }
  }
  .answer-panel {
    .panel-wrap {
      transition: opacity 0.5s ease;
    }
    nav {
      button {
        order: 0;
      }
      &:before {
        order: 1;
        margin-left: 4rem;
        margin-right: 0;
      }
    }
    .panel-content {
      textarea {
        margin-bottom: 1rem;
        height: 12rem;
      }
      p.remaining {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-bottom: 4rem;
      }
      button {
        display: block;
        font-size: 1.2rem;
        text-transform: uppercase;
        text-align: center;
        border: 0.1rem solid #000;
        background: transparent;
        color: #000;
      }
    }
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 200;
      text-align: center;

      svg {
        margin-bottom: 2rem;
      }
    }
    &.sending {
      pointer-events: none;

      .panel-wrap {
        opacity: 0.3;
      }
    }
  }
  .question-panel {
    display: flex;
    flex-direction: column;
    height: 100%;

    .panel-content {
      h4 {
        text-transform: uppercase;
        font-weight: 200;
        font-size: 1.2rem;
      }
      .question-controls {
        margin-bottom: 8rem;
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        button {
          display: block;
          width: calc(50% - 0.5rem);
          font-size: 1.2rem;
          text-transform: uppercase;
          text-align: center;
          border: 0.1rem solid #000;

          &.refresh {
            background: transparent;
            color: #000;
          }
        }
      }
      .question-meta {
        font-weight: lighter;
        text-transform: uppercase;
        font-size: 1.2rem;
        border-bottom: 0.1rem dashed #000000;

        > div {
          border-top: 0.1rem dashed #000000;
          display: flex;
          align-items: center;
          padding: 0.5rem;

          > span {
            flex: 1;
          }
        }
      }
    }
  }
  .panel-content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
